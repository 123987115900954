import axios from 'axios';


//current_branch="$(git branch | grep \* | cut -d ' ' -f2)"
const api = axios.create({
  //develop
  // baseURL: 'https://api39.gamequiz.gruposaite.com.br/',
  //stading
  baseURL: 'https://api40.gamequiz.gruposaite.com.br/',
  //production
  //baseURL: 'https://api.gamequiz.gruposaite.com.br/',
  //local
  // baseURL: 'http://localhost:3000/',
  responseType: "json"
})

export default api;