/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import api from "services/Api";
import { Badge } from "reactstrap";

// reactstrap components
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  // CardTitle,
  // CardText,
  Col,
  Navbar,
  Card,
  // Spinner,
  Form,
  FormGroup,
  Label,
  //Input,
  Button,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  Jumbotron,
  Table,
  Container,
  Media,
  Row,
  Modal,
  // ModalHeader,
  //  ModalBody,
  //  ModalFooter,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import CharCountInput from "components/Inputs/CharCountInput.js";

class ListQuestions extends React.Component {
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      questoes: [],
      users: [],
      information: "",
      name: "",
      activeTab: "1",
      email: "",
      defaultModal: false,
      qt: [],
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitUser = this.onSubmitUser.bind(this);
  }
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    if (localStorage.usertoken) {
      try {
        this.loadingPage = true;
        //verificar autenticação
        api.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.usertoken;
        api
          .post("auth/")
          .then((res) => {
            //autorizado
          })
          .catch((err) => {
            localStorage.clear();
            this.props.history.push("/auth/login");
          });

        const questionario = localStorage.getItem("questionarioID");

        api
          .get("questions/questionnaires/" + questionario)
          .then((res) => {
            const questoes = res.data;
            //console.log('tema ->',questionario)
            //console.log('questionnario ->',questoes.questionnaire)
            //console.log('users ->',questoes.questionnaire.users)

            this.setState({
              users: questoes.questionnaire.users,
              questoes: questoes,
              information: questoes.questionnaire.information,
              name: questoes.questionnaire.name,
              qt: questoes.questionnaire.questions,
            });

            this.loadingPage = false;
            //console.log("state := ", this.state);
          })
          .catch((err) => {
            this.loadingPage = false;
            alert("Erro ao carregar questoes");
          });
      } catch (err) {
        this.loadingPage = false;
        localStorage.clear();
        this.props.history.push("/auth/login");
        alert("Logout...");
      }
    }
  }

  goEdit(e, id, pos) {
    e.preventDefault();
    localStorage.setItem("questionID", id);
    this.props.history.push("editQuestion");
  }

  goCreate(e, questionario) {
    e.preventDefault();

    this.props.history.push("registerQuestion");
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const questID = localStorage.getItem("questionarioID");
    localStorage.setItem("questionarioName", this.state.name);

    api
      .put("/questions/questionnaires/" + questID, {
        name: this.state.name,
        information: this.state.information,
      })
      .then((res) => {
        alert("Questionário editado com sucesso");
        //this.props.history.push('/admin/listQuestions')
      })
      .catch((err) => {
        this.alertErro("ERRO", "Erro ao editar questionário");
        //alert("Erro ao editar questionário")
      });
  }
  onSubmitUser(e) {
    e.preventDefault();
    const questionarioiD = localStorage.getItem("questionarioID");
    localStorage.setItem("questionarioName", this.state.name);

    api
      .put(
        "/questions/questionnaires/addUser/" +
          this.state.email +
          "/" +
          questionarioiD
      )
      .then((res) => {
        alert("Usuário adicionado com sucesso");
      })
      .catch((err) => {
        this.alertErro(
          "ERRO",
          "Erro ao adicionar usuário questionário\nVerificar se usuário já está cadastrado"
        );
        //alert("Erro ao adicionar usuário questionário\nVerificar se usuário já está cadastrado")
      });
  }
  /*
  goBack(e){
    e.preventDefault()

    this.props.history.goBack()
  }
*/
  getHtmlUsers() {
    const users = this.state.users;
    //console.log("users = >")
    //console.log(users)
    let lista = [];
    for (let i in users) {
      //console.log(users[i])
      lista.push(
        <tr key={i}>
          <td>{parseInt(i) + parseInt(1)}</td>
          <td>
            <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  alt=""
                  //src={require("../../assets/img/theme/GameQuiz-Icone.png")}
                  src={users[i].image}
                />
              </span>
            </Media>
          </td>
          <td>{users[i].name}</td>
          <td>{users[i].email}</td>
          <td className="text-right">
            {/*<UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href=""
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href=""
                              onClick={e => e.preventDefault()}
                            >
                              Excluir
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>*/}
          </td>
        </tr>
      );
    }
    return lista;
  }

  getHtmlQuestoes() {
    const questoes = this.state.questoes;
    //console.log("quesotes")
    //console.log(questoes)
    let dificuldade = "Fácil";
    let gabarito = "A";
    let lista = [];
    for (let i in questoes) {
      for (let j in questoes[i].questions) {
        if (questoes[i].questions[j].dificuldade === 1) {
          dificuldade = "Fácil";
        } else if (questoes[i].questions[j].dificuldade === 2) {
          dificuldade = "Média";
        } else if (questoes[i].questions[j].dificuldade === 3) {
          dificuldade = "Difícil";
        }
        if (questoes[i].questions[j].correta === 0) {
          gabarito = "A";
        } else if (questoes[i].questions[j].correta === 1) {
          gabarito = "B";
        } else if (questoes[i].questions[j].correta === 2) {
          gabarito = "C";
        } else if (questoes[i].questions[j].correta === 3) {
          gabarito = "D";
        }
        lista.push(
          <tr key={j}>
            <td>{parseInt(j) + parseInt(1)}</td>
            <td style={{ whiteSpace: "unset" }}>
              {questoes[i].questions[j].pergunta}
            </td>
            <td>{gabarito}</td>
            <td>{dificuldade}</td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  id="BTNOptions"
                  href=""
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href=""
                    onClick={(e) =>
                      this.goEdit(e, questoes[i].questions[j]._id, i)
                    }
                  >
                    Editar
                  </DropdownItem>
                  <DropdownItem
                    href=""
                    onClick={() =>
                      this.alertExcludeQuestion(questoes[i].questions[j]._id)
                    }
                  >
                    Excluir
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
        );
      }
    }
    // if(lista.length<1&&(!haveone)){
    //   return  <Spinner color="info" />
    // }
    return lista;
  }
  getHtmlVisualization() {
    const questoes = this.state.qt;
    let hashDificuldade = { 1: "Fácil", 2: "Médio", 3: "Difícil" };
    let hashCorreta = { 0: "A", 1: "B", 2: "C", 3: "D" };
    let lista = [];
    //console.log("HTMLVisualization")
    //console.log(questoes)

    let badge = {};
    for (let i in questoes) {

      let aux = {};
      if (questoes[i].pergunta.length > 323) {

        aux.pergunta = {
          color: 'danger',
          text: 'excede 323 caracteres',
        }
      } else {
        aux.dica = {
          color: 'success',
          text: 'status: ok',
        }
      }

      if (questoes[i].dica.length > 250) {

        aux.dica = {
          color: "danger",
          text: "excede 250 caracteres",
        }
      }else{
        aux.dica = {
          color: 'success',
          text: 'status: ok',
        }
      }

      if (questoes[i].alternativas[0].length > 140) {
        
        aux.altA = {
          color: "danger",
          text: "excede 140 caracteres",
        }
      }else{
        aux.altA = {
          color: 'success',
          text: 'status: ok',
        }
      }
      // if((questoes[i].alternativas[0].length > 140) ){
      //   badge.fedA.color = "danger"
      //   badge.fedA.text = "excede 140 caracteres"
      // }

      if (questoes[i].alternativas[1].length > 140) {
        aux.altB = {
          color: "danger",
          text: "excede 140 caracteres",
        }
      }else{
        aux.altB = {
          color: 'success',
          text: 'status: ok',
        }
      }
      // if((questoes[i].feedback[1].length > 140) ){
      //   badge.fedB.color = "danger"
      //   badge.fedB.text = "excede 140 caracteres"
      // }

      if (questoes[i].alternativas[2].length > 140) {
        aux.altC = {
          color: "danger",
          text: "excede 140 caracteres",
        };
      }else{
        aux.altC = {
          color: 'success',
          text: 'status: ok',
        }
      }
      // if((questoes[i].feedback[2].length > 150) ){
      //   badge.fedC.color = "danger"
      //   badge.fedC.text = "excede 150 caracteres"
      // }

      if (questoes[i].alternativas[3].length > 140) {
        aux.altD = {
          color: "danger",
          text: "excede 140 caracteres",
        };
      }else{
        aux.altD = {
          color: 'success',
          text: 'status: ok',
        }
      }
      // if((questoes[i].feedback[3].length > 150) ){
      //   badge.fedD.color = "danger"
      //   badge.fedD.text = "excede 150 caracteres"
      // }

      badge[i] = aux;

      lista.push(
        <div key={i}>
          <hr className="my-3" />
          <Jumbotron className="bg-default shadow">
            <span className="h2 text-white font-weight-bold mb-0">
              Pergunta {parseInt(i) + parseInt(1)}
            </span>
            {/* <span className="h2 text-white font-weight-bold mb-0">Pergunta {(parseInt(i)+parseInt(1))}
            <Badge className="float-right" color={badge.pergunta.color} >
                  {`${badge.pergunta.text}`}
            </Badge>
            </span>  */}
            <hr className="my-1" />
            <p className="text-white">{questoes[i].pergunta}</p>
            <span className="h2 text-white font-weight-bold mb-0">Dica</span>
            {/* <span className="h2 text-white font-weight-bold mb-0">Dica
            <Badge className="float-right" color={badge[i].dica.color} >
                  {`${badge[i].dica.text}`}
            </Badge>
            </span>  */}
            <hr className="my-1" />
            <p className="text-white">{questoes[i].dica}</p>

            <span className="h2 text-white font-weight-bold mb-0">
              Dificuldade
            </span>
            <hr className="my-1" />
            <p className="text-white">
              {hashDificuldade[questoes[i].dificuldade]}
            </p>

            <span className="h2 text-white font-weight-bold mb-0">Correta</span>
            <hr className="my-1" />
            <p className="text-white">{hashCorreta[questoes[i].correta]}</p>
            <hr className="my-2" />

            {/* Alternativas */}

            <span className="h2 text-white font-weight-bold mb-0">
              Alternativas
            </span>
            <hr className="my-1" />
            {/* <span className="h3 text-white font-weight-bold mb-0">A</span> */}
            <span className="h3 text-white font-weight-bold mb-0">
              A
              <Badge className="float-right" color={badge[i].altA.color}>
                {`${badge[i].altA.text}`}
              </Badge>
            </span>
            <p className="text-white">{questoes[i].alternativas[0]}</p>
            <span className="h3 text-white font-weight-bold mb-0">
              Feedback A
            </span>
            {/* <span className="h3 text-white font-weight-bold mb-0">Feedback A
            <Badge className="float-right" color={badge.fedA.color} >
                  {`${badge.fedA.text}`}
            </Badge>
            </span>  */}
            <p className="text-white">{questoes[i].feedback[0]}</p>
            <hr className="my-1" />
            {/* <span className="h3 text-white font-weight-bold mb-0">B</span> */}
            <span className="h3 text-white font-weight-bold mb-0">
              B
              <Badge className="float-right" color={badge[i].altB.color}>
                {`${badge[i].altB.text}`}
              </Badge>
            </span>
            <p className="text-white">{questoes[i].alternativas[1]}</p>
            <span className="h3 text-white font-weight-bold mb-0">
              Feedback B
            </span>
            {/* <span className="h3 text-white font-weight-bold mb-0">Feedback B
            <Badge className="float-right" color={badge.fedB.color} >
                  {`${badge.fedB.text}`}
            </Badge>
            </span>  */}
            <p className="text-white">{questoes[i].feedback[1]}</p>
            <hr className="my-1" />
            {/* <span className="h3 text-white font-weight-bold mb-0">C</span> */}
            <span className="h3 text-white font-weight-bold mb-0">
              C
              <Badge className="float-right" color={badge[i].altC.color}>
                {`${badge[i].altC.text}`}
              </Badge>
            </span>
            <p className="text-white">{questoes[i].alternativas[2]}</p>
            <span className="h3 text-white font-weight-bold mb-0">
              Feedback C
            </span>
            {/* <span className="h3 text-white font-weight-bold mb-0">Feedback C
            <Badge className="float-right" color={badge.fedC.color} >
                  {`${badge.fedC.text}`}
            </Badge>
            </span>  */}
            <p className="text-white">{questoes[i].feedback[2]}</p>
            <hr className="my-1" />
            {/* <span className="h3 text-white font-weight-bold mb-0">D</span> */}
            <span className="h3 text-white font-weight-bold mb-0">
              D
              <Badge className="float-right" color={badge[i].altD.color}>
                {`${badge[i].altD.text}`}
              </Badge>
            </span>

            <p className="text-white">{questoes[i].alternativas[3]}</p>
            <span className="h3 text-white font-weight-bold mb-0">
              Feedback D
            </span>
            {/* <span className="h3 text-white font-weight-bold mb-0">Feedback D
            <Badge className="float-right" color={badge.fedD.color} >
                  {`${badge.fedD.text}`}
            </Badge>
            </span>  */}
            <p className="text-white">{questoes[i].feedback[3]}</p>
          </Jumbotron>
        </div>
      );
    }

    return lista;
  }

  alertExcludeQuestion(questionId) {
    //colocar no localstorage o questionId
    localStorage.setItem("questionID", questionId);
    //ativar o modal da notificação de excluir
    this.toggleModal("notificationExcludeModal");
  }
  excludeQuestion() {
    //pegar o id do localstorage
    const questionID = localStorage.getItem("questionID");
    //mandar delete pra api a exclusão da questão (usuário já confirmou)
    ///question/:questionId

    api
      .delete("/questions/question/" + questionID)
      .then((res) => {
        alert("questão deletada com sucesso");
        this.toggleModal("notificationExcludeModal");
        window.location.reload();
      })
      .catch((err) => {
        this.alertErro("ERRO", "Erro ao excluir questão");
        this.toggleModal("notificationExcludeModal");
      });
  }

  getHtmlTooltips() {
    let saida = (
      <div>
        <UncontrolledTooltip
          delay={0}
          placement="top"
          target="tooltipVisualizarQuestoes"
        >
          Visualizar Todas as Questões
        </UncontrolledTooltip>
        <UncontrolledTooltip delay={0} placement="top" target="NavlinkUsuarios">
          Visualizar Todas as pessoas autorizadas a editar
        </UncontrolledTooltip>
        <UncontrolledTooltip delay={0} placement="top" target="NavlinkQuestoes">
          Visualizar Todas as questões já cadastrada
        </UncontrolledTooltip>
        <UncontrolledTooltip delay={0} placement="left" target="name">
          Nome do Quiz
        </UncontrolledTooltip>
        <UncontrolledTooltip delay={0} placement="left" target="information">
          Informação sobre o Quiz, mostrada ao usuário dentro do jogo
        </UncontrolledTooltip>
        <UncontrolledTooltip delay={0} placement="top" target="BTNCriarQuestao">
          Cria uma nova Questão para este Quiz
        </UncontrolledTooltip>
        <UncontrolledTooltip delay={0} placement="top" target="BTNAddUser">
          Autoriza outro usuário a também editar o Quiz.
        </UncontrolledTooltip>
      </div>
    );
    return saida;
  }
  onKeyPress(event) {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  render() {
    const questoes = this.getHtmlQuestoes();
    const users = this.getHtmlUsers();
    const visualization = this.getHtmlVisualization();
    //console.log('here ->',questoes)
    const tooltips = this.getHtmlTooltips();
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* TOOLTIPS */}
          {tooltips}
          {/* Dark table */}
          <Row className="mt-5">
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col>
                      <h3 className="text-white mb-2">
                        Questões - {localStorage.getItem("questionarioName")}
                      </h3>
                    </Col>
                    <Col>
                      <hr className="my-2" />
                    </Col>
                    <Col>
                      <hr className="my-2" />
                    </Col>

                    <Col>
                      <Button
                        className="btn-icon btn-2"
                        size="lg"
                        color="info"
                        data-placement="top"
                        id="tooltipVisualizarQuestoes"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>
                          this.toggleModal("VisualizeQuestionnaireModal")
                        }
                      >
                        <span className="btn-inner--icon">
                          <i className="far fa-eye"></i>
                        </span>
                        Visualizar
                      </Button>
                      {/* Modal */}
                      <Modal
                        className="modal-dialog-centered"
                        size="lg"
                        isOpen={this.state.VisualizeQuestionnaireModal}
                        toggle={() =>
                          this.toggleModal("VisualizeQuestionnaireModal")
                        }
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="VisualizeQuestionnaireModal"
                          >
                            Visualizar Questionário
                          </h5>
                          <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() =>
                              this.toggleModal("VisualizeQuestionnaireModal")
                            }
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* Qeustões */}
                          <h2>{localStorage.getItem("questionarioName")}</h2>
                          {visualization}
                        </div>
                        <div className="modal-footer">
                          <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() =>
                              this.toggleModal("VisualizeQuestionnaireModal")
                            }
                          >
                            Close
                          </Button>
                        </div>
                      </Modal>
                    </Col>
                  </Row>
                </CardHeader>

                {/*
                <div> DIV informações do quesionário disponíveis pra edição (nome e informações) criar form</div>
                 */}
                <Card className="bg-default shadow">
                  <CardHeader className="bg-transparent border-0">
                    <Form
                      onKeyPress={this.onKeyPress}
                      noValidate
                      onSubmit={this.onSubmit}
                    >
                      <FormGroup>
                        <Label for="name" className="text-white mb-2">
                          Nome
                        </Label>
                        <CharCountInput
                          value={this.state.name}
                          // maxLength="20"
                          onChange={this.onChange}
                          type="text"
                          name="name"
                          id="name"
                          placeholder={"nome do questionário"}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="information" className="text-white mb-2">
                          Informação
                        </Label>
                        <CharCountInput
                          value={this.state.information}
                          // maxLength="150"
                          onChange={this.onChange}
                          rows="2"
                          type="textarea"
                          name="information"
                          id="information"
                          placeholder={
                            "Quiz direcioado a quem está estudando sobre ..."
                          }
                        />
                      </FormGroup>

                      <div className="text-center">
                        <Button className="my-4" color="primary" type="submit">
                          Salvar
                        </Button>
                        <button
                          type="button"
                          onClick={() =>
                            this.props.history.push("/admin/questionnaires")
                          }
                          className="btn btn-danger cosmo-color-1"
                        >
                          Voltar
                        </button>
                      </div>
                    </Form>
                  </CardHeader>
                </Card>
              </Card>
              <div>
                {/* <Button
                  block
                  className="mb-3"
                  color="warning"
                  type="button"
                  onClick={() => this.toggleModal("notificationExcludeModal")}
                >
                  Notification
                </Button> */}
                <Modal
                  className="modal-dialog-centered modal-danger"
                  contentClassName="bg-gradient-danger"
                  isOpen={this.state.notificationExcludeModal}
                  toggle={() => this.toggleModal("notificationExcludeModal")}
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                      Apagar Questão
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() =>
                        this.toggleModal("notificationExcludeModal")
                      }
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="py-3 text-center">
                      {/* <i className="ni ni-bell-55 ni-3x" /> */}
                      <i className="fas fa-exclamation-triangle ni-3x"></i>
                      <h4 className="heading mt-4">ATENÇÃO!</h4>
                      <p>
                        Você está prestes a apagar a questão do banco de dados,
                        essa operação não pode ser desfeita.
                      </p>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="btn-white"
                      color="default"
                      onClick={() => this.excludeQuestion()}
                      type="button"
                    >
                      OK, confirmar
                    </Button>
                    <Button
                      className="text-white ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() =>
                        this.toggleModal("notificationExcludeModal")
                      }
                    >
                      Fechar
                    </Button>
                  </div>
                </Modal>
              </div>
              <hr className="my-2" />
              <div>
                <Navbar className="myNav" expand="md">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        id="NavlinkQuestoes"
                        href="#"
                        onClick={(e) => this.toggleTab("1")}
                      >
                        Questões
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        id="NavlinkUsuarios"
                        href="#"
                        onClick={(e) => this.toggleTab("2")}
                      >
                        Usuários
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Navbar>
              </div>
              <Card className="bg-default shadow">
                <div>
                  {/*<link
                  rel='stylesheet'
                  href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css'
                />*/}

                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <CardHeader className="bg-transparent border-0">
                        <Button
                          id="BTNCriarQuestao"
                          className="mr-4"
                          color="info"
                          href=""
                          onClick={(e) =>
                            this.goCreate(
                              e,
                              localStorage.getItem("questionarioID")
                            )
                          }
                          size="sm"
                        >
                          Criar nova Questão
                        </Button>
                      </CardHeader>
                      <Table
                        bordered
                        hover
                        className="align-items-center table-dark table-flush Table"
                        responsive
                      >
                        <thead className="thead-dark">
                          <tr>
                            <th>#</th>
                            <th scope="col">Pergunta</th>
                            <th scope="col">Gabarito</th>
                            <th scope="col">Dificuldade</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {/*this.loadingPage?<Spinner color="info" />:questoes*/}
                          {questoes}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="2">
                      <Card className="bg-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Form
                            onKeyPress={this.onKeyPress}
                            noValidate
                            onSubmit={this.onSubmitUser}
                          >
                            <FormGroup>
                              <Label for="email" className="text-white mb-2">
                                Email
                              </Label>
                              <CharCountInput
                                value={this.state.email}
                                onChange={this.onChange}
                                type="text"
                                name="email"
                                id="email"
                                placeholder={"example@gmail.com"}
                              />
                            </FormGroup>

                            <div className="text-center">
                              <Button
                                id="BTNAddUser"
                                className="my-4"
                                color="primary"
                                type="submit"
                              >
                                Adicionar Usuário
                              </Button>
                            </div>
                          </Form>
                          <UncontrolledAlert color="warning" fade={false}>
                            <span className="alert-inner--icon">
                              <i className="ni ni-like-2" />
                            </span>{" "}
                            <span className="alert-inner--text">
                              <strong>Aviso!</strong> Antes de ser adicionado
                              esse email deve ter entrado ao menos uma vez nesta
                              ferramenta!
                            </span>
                          </UncontrolledAlert>
                        </CardHeader>
                      </Card>
                      <Table
                        bordered
                        hover
                        className="align-items-center table-dark table-flush Table"
                        responsive
                      >
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" />
                            <th scope="col">Nome</th>
                            <th scope="col">email</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {/*this.loadingPage?<Spinner color="info" />:users*/}
                          {users}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ListQuestions;
